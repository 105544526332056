import React, { useEffect, useRef, useState } from "react";

import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { Grid, makeStyles } from "@material-ui/core";

import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";
import { format } from "date-fns";

import DateForm from "../../../components/datepicker/datepicker.component";
import ExpandableSelect from "../../../components/select/expandableSelect.component";
import InputForm from "../../../components/input/input.component";
import SelectForm from "../../../components/select/select.component";
import ToggleButtons from "../../../components/toggle/toggle.component";
import TooltipMessage from "../../../components/tooltip/tootltip-message.component";

import { useFormComplete } from "../../../hooks/useFormComplete";

import {
  getAllCitiesAction,
  getCities,
  getDocumentTypesAction,
  getPaymentMethodsAction,
  getSupportDocumentTypeAction,
  getRegimeTypesAction,
  getStatesAction,
  getPostalCode,
  getRequestTransmissionType,
  getCountriesAction,
} from "../../../actions/configAction";
import { getAditionalData } from "../../../actions/invoiceActions";

import calculateDV from "../../../utils/calculateDV";
import { getDateMinPay, getDateNotAfterDS, getDateNotBeforeDS, getDatepay } from "../../../utils/rangeDates";
import { getEnterpriseId, getUserIdSecurity } from "../../../utils/dataUserLogin";
import {
  cleanDocumentSupportAction,
  cleanSuplierAndProviderAction,
  dataProviderAction,
  documentDataAction,
  getSupplierDataAction,
} from "../../../actions/documentSupportAction";
import CustomTooltip from "../../../components/tooltip/tooltip.component";
import { formatDate } from "../../../utils/general";
import MultiSelectForm from "../../../components/multiselect/multiselect.component";
import CustomAutoComplete from "../../../components/autocomplete/autocomplete.component";
import { sortByProperty } from "../../../utils/filterByIdState";

const useStyles = makeStyles({
  titleData: {
    background: "rgba(18, 18, 18, 0.3)",
    borderRadius: "5px",
    color: "#2CC63E",
    fontFamily: "Muli",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "23px",
    padding: "10px 0",
    textAlign: "center",
  },
  containerError: {
    color: "#f44336",
    fontSize: 14,
    textAlign: "left",
    paddingTop: "2%",
    marginLeft: "2%",
  },
});

const DocumentInformation = ({ handleChangeStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [, forceUpdate] = useState();
  const [stateciudad, setStateciudad] = useState("");
  const [documentBranchData, setDocumentBranchData] = useState([]);
  const [documentResolutionData, setDocumentResolutionData] = useState([]);
  const [typesDocumentFiltered, setTypesDocumentFiltered] = useState([]);
  const [errors, setErrors] = useState([]);

  const { personTypeTitle, personTypeMessage, personTypeButton, personTypeHref } = useSelector((state) => state.menuReducer.menuCustomer);

  const {
    titlePostalCode,
    messagePostalCode,
    bottonPostalCode,
    urlPostalCode,
    titleSellerOrigin,
    messageSellerOrigin,
    bottonSellerOrigin,
    urlSellerOrigin,
  } = useSelector((state) => state.menuReducer.menuMyAccount);

  const { supplierData, providerData, documentData } = useSelector((state) => state.documentSupportReducer);

  const { aditionaldataenterprise } = useSelector((state) => state.invoiceReducer);

  const validator = useRef(new SimpleReactValidator(reactValidatorOptions)).current;

  const { defaultPaymentMethods, othersPaymentMethods } = useSelector((s) => s.configReducer, shallowEqual);

  const { values, handleInputChange, handleUpdateForm, handleAutocompleteChange, initialState } = useFormComplete({
    correoelectronico: "",
    pais: "",
    departamento: "",
    documentodv: "",
    direccion: "",
    fechaFactura: format(new Date(), "yyyy/MM/dd"),
    fechapago: format(getDatepay(), "yyyy/MM/dd"),
    formaPago: "1",
    daneciudadid: "",
    municipioPais: "",
    numerodocumento: "",
    observaciones: "",
    paymethodid: "10",
    descripcionPaymethodid: "Efectivo",
    rangofecha: 0,
    razonsocial: "",
    diantiporegimenid: "",
    responsabilidadTributariaId: "",
    responsabilidadFiscal: [],
    resolucionFactura: "",
    tipoDeTransmision: "",
    sucursal: "",
    telefono: "",
    diantipodocumentoid: "",
    diantipopersonaid: "2",
    procedenciaVendedor: "1",
    nombre: "",
    apellido: "",
    codigopostalid: "",
  });

  const {
    getSupportDocumentType: typesDocument,
    getRegimeTypesResponse: typesRegime,
    getFiscalResponsibility: fiscalResponsibility,
    getTaxResponsibility: taxResponsibility,
    getCountriesResponse: countriesList,
    getStatesResponse: statesList,
    requestTransmissionType,
    getCitiesStateResponse,
    allCitiesCountry,
    getPostalCodesState,
  } = useSelector((state) => state.configReducer);

  const taxFilter = taxResponsibility.filter((item) => item.id === 1 || item.id === 4);

  const {
    //! datos del proveedor
    diantipodocumentoid,
    numerodocumento,
    documentodv,
    diantipopersonaid,
    procedenciaVendedor,
    razonsocial,
    diantiporegimenid,
    responsabilidadFiscal,
    responsabilidadTributariaId,
    correoelectronico,
    telefono,
    pais,
    departamento,
    daneciudadid,
    municipioPais,
    direccion,
    nombre,
    apellido,
    codigopostalid,
    //! Datos del documento
    tipoDeTransmision,
    fechaFactura,
    fechapago,
    formaPago,
    observaciones,
    paymethodid,
    descripcionPaymethodid,
    resolucionFactura,
    sucursal,
    rangofecha,
  } = values;

  /**
   * *Cargue Inicial
   */
  useEffect(() => {
    dispatch(getAllCitiesAction());
    dispatch(getSupportDocumentTypeAction());
    dispatch(getRegimeTypesAction());
    dispatch(getStatesAction());
    dispatch(getRequestTransmissionType());
    dispatch(getCountriesAction());
    dispatch(getDocumentTypesAction());
    if (departamento !== "") {
      dispatch(getCities(departamento));
      if (departamento !== stateciudad) {
        handleUpdateForm({
          daneciudadid: providerData?.daneciudadid || supplierData?.daneciudadid || "",
          codigopostalid: providerData?.codigopostalid || supplierData?.codigopostalid || "",
        });
        dispatch(cleanDocumentSupportAction());
      }
    }

    /**
     * *Cargue de datos en Codigos Postales
     * * Se modifica cada vez que se cambia el Municipio(ciudadid)
     */
    if (daneciudadid !== "") {
      dispatch(getPostalCode(daneciudadid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departamento, dispatch, daneciudadid]);

  /**
   * *Carga los metodos de pago
   * *y los metodos de pago adicionales
   */
  useEffect(() => {
    dispatch(getPaymentMethodsAction());
    dispatch(getAditionalData(getEnterpriseId(), getUserIdSecurity()));
  }, [dispatch]);

  useEffect(() => {
    setStateciudad(departamento);
  }, [departamento]);

  useEffect(() => {
    if (procedenciaVendedor === "1") {
      handleUpdateForm({
        diantipodocumentoid: "3",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procedenciaVendedor]);

  /**
   * * Consulta si los datos de proveedor ya existen en BD y llena los campos si existe
   * *
   */
  useEffect(() => {
    if (!providerData?.diantipodocumentoid && supplierData?.diantipodocumentoid) {
      handleUpdateForm({
        diantipodocumentoid: supplierData.diantipodocumentoid.toString(),
        numerodocumento: supplierData.numerodocumento,
        correoelectronico: supplierData.correoelectronico,
        documentodv: supplierData.documentodv || "",
        direccion: supplierData.direccion,
        departamento: supplierData?.daneciudadid ? getDepartamento(supplierData.daneciudadid) : "",
        daneciudadid: supplierData?.daneciudadid,
        municipioPais: supplierData?.ciudadnoresidente ?? "",
        pais: getCountry(supplierData.dianpaisid),
        procedenciaVendedor:
          supplierData.residenteid === null && supplierData.diantipodocumentoid !== 3 ? "2" : supplierData?.residenteid?.toString() || "1",
        razonsocial: supplierData.razonsocial || supplierData.nombre + " " + supplierData.apellido,
        diantiporegimenid: supplierData.diantiporegimenid,
        responsabilidadTributariaId: supplierData.responsabilidadtributariaid?.toString(),
        responsabilidadFiscal: supplierData.responsabilidadfiscal?.split("") ?? [],
        telefono: supplierData.telefono,
        diantipopersonaid: supplierData.diantipopersonaid.toString() || "2",
        nombre: supplierData.nombre || "",
        apellido: supplierData.apellido || "",
        codigopostalid: supplierData.codigopostalid,

        // Datos del documento
        tipoDeTransmision: documentData?.tipoOperacion ?? "",
        fechaFactura: documentData?.fechaFactura || format(new Date(), "yyyy/MM/dd"),
        fechapago: documentData.fechapago || format(getDatepay(), "yyyy/MM/dd"),
        formaPago: documentData.formaPago || "1",
        observaciones: documentData.observaciones,
        paymethodid: documentData.paymethodid || "10",
        resolucionFactura: documentData.resolucionFactura,
        sucursal: documentData.sucursal,
        rangofecha: documentData.rangofecha,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierData]);

  /**
   * * Consulta si el usuario ya lleno los campos de proveedor y llena los campos si existe
   */

  useEffect(() => {
    if (providerData?.diantipodocumentoid && documentData?.fechaFactura) {
      handleUpdateForm({
        diantipodocumentoid: providerData.diantipodocumentoid.toString(),
        numerodocumento: providerData.numerodocumento,
        correoelectronico: providerData.correoelectronico,
        documentodv: providerData.documentodv,
        direccion: providerData.direccion,
        departamento: providerData.daneciudadid ? getDepartamento(providerData.daneciudadid) : "",
        daneciudadid: providerData.daneciudadid,
        municipioPais: providerData.ciudadnoresidente,
        pais: getCountry(providerData.dianpaisid),
        procedenciaVendedor: providerData.residenteid?.toString() || "1",
        razonsocial: providerData.razonsocial || providerData.nombre + " " + providerData.apellido,
        nombre: providerData.nombre || "",
        apellido: providerData.apellido || "",
        codigopostalid: providerData.codigopostalid,
        diantiporegimenid: providerData.diantiporegimenid,
        responsabilidadTributariaId: providerData.responsabilidadtributariaid?.toString(),
        responsabilidadFiscal: providerData.responsabilidadfiscal?.split("") ?? [],
        telefono: providerData.telefono,
        diantipopersonaid: providerData?.diantipopersonaid.toString(),
        tipoDeTransmision: documentData.tipoDeTransmision,
        fechaFactura: documentData.fechaFactura,
        fechapago: documentData.fechapago,
        formaPago: documentData.formaPago,
        observaciones: documentData.observaciones,
        paymethodid: documentData.paymethodid,
        resolucionFactura: documentData.resolucionFactura,
        sucursal: documentData.sucursal,
        rangofecha: documentData.rangofecha,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerData, supplierData, documentData]);

  useEffect(() => {
    let paymethod = "";
    if (paymethodid) {
      paymethod = defaultPaymentMethods.find((item) => item.value === paymethodid);
      if (paymethod === undefined) {
        paymethod = othersPaymentMethods.find((item) => item.value === paymethodid);
      }

      if (paymethod !== undefined) {
        handleUpdateForm({
          descripcionPaymethodid: paymethod.descripcion,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPaymentMethods, paymethodid]);

  /**
   * *filtra los datos de la sucursal si es activa y busca si tiene una resolucion relacionada a esa sucursal.
   */
  useEffect(() => {
    const branchActive = aditionaldataenterprise.adsucursalesempresa.filter((s) => s.idstate === 0);

    setDocumentBranchData(branchActive);

    const documentResolutionData = aditionaldataenterprise.adnumeracion.filter(
      (n) => n.sucursalempresaid === sucursal && n.diantipomodalidadid === 6
    );

    setDocumentResolutionData(documentResolutionData);
  }, [aditionaldataenterprise, sucursal]);

  useEffect(() => {
    setTypesDocumentFiltered(getTypesDocument());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procedenciaVendedor, typesDocument]);

  /**
   * * Limpia los Errores del Validador al cambiar de procedencia del vendedor o tipo de documento
   */
  useEffect(() => {
    validator.purgeFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procedenciaVendedor, diantipodocumentoid]);

  /**
   * *Sincroniza nuevo valor fecha pago
   * @param {*} id
   * @param {*} date
   * @param {*} name
   */
  const handleDatePay = (id, date, name) => {
    handleUpdateForm({
      fechapago: new Date(date).toISOString(),
      rangofecha: 0,
    });
  };

  /**
   * *Sincroniza nuevo valor forma de pago, limpia medio de pago para forma de pago "credito"
   * @param {*} e
   */
  const handleChangePaymentMethod = (e, value) => {
    handleUpdateForm({
      formaPago: value || formaPago,
    });
    validator.purgeFields();
  };
  /**
   * *Sincroniza nuevo tipo de persona"
   * @param {*} e
   */
  const handleChangeKindPerson = (e, value) => {
    handleUpdateForm({
      diantipopersonaid: value || diantipopersonaid,
    });
  };

  const handleSellerOriginChange = (e, value) => {
    handleUpdateForm({
      ...initialState,
      procedenciaVendedor: value || procedenciaVendedor,
    });
    dispatch(cleanSuplierAndProviderAction());
  };

  /**
   * *Calcula digito de verificación de documento.
   * *actualiza formulario
   * *@param {*} e
   */

  const onBlurDocument = (e) => {
    e.preventDefault();
    const document = e.target.value;
    if ([3].includes(Number(diantipodocumentoid))) {
      let dv = calculateDV(document);
      handleUpdateForm({
        documentodv: dv,
      });
    }

    if (diantipodocumentoid !== "" && numerodocumento !== "") {
      dispatch(getSupplierDataAction(diantipodocumentoid, numerodocumento));
    }
  };

  /**
   * *Validadores depues de OnBlur en caja de texto
   * *@param {object} e Evento
   * *@param {string} name Nombre validador
   */

  const validateFieldsOnBlur = (e, name) => {
    if (!validator.fieldValid(name)) {
      validator.showMessageFor(name);
    }
  };

  /**
   * * Obtiene el departamento correspondiente al ID proporcionado.
   * @param {number} data - El ID del departamento.
   * @returns {string} - El ID del departamento correspondiente o una cadena vacía si no se encuentra.
   */
  const getDepartamento = (data) => {
    const { departamentoid } = allCitiesCountry.find((states) => states.id === Number(data));
    return departamentoid || "";
  };

  // /**
  //  * * Obtiene el nombre de la ciudad.
  //  * @param {number} idCity - El ID de la ciudad.
  //  * @returns {string} El nombre de la ciudad.
  //  */
  // const getCityCountry = (idCity) => {
  //   const { descripcion } = allCitiesCountry.find(
  //     (city) => city.id === Number(idCity)
  //   );
  //   return descripcion;
  // };

  /**
   * * Obtiene el país correspondiente al ID proporcionado.
   * @param {number} idCountry - El ID del país.
   * @returns {object} - El objeto país correspondiente al ID proporcionado. Si no se encuentra, se devuelve el objeto país con ID 1.
   */
  const getCountry = (idCountry) => {
    const countryFound = countriesList.find((country) => country.id === Number(idCountry));
    return countryFound || "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let documentData = {
      fechaFactura,
      fechapago,
      formaPago,
      observaciones,
      paymethodid,
      descripcionPaymethodid,
      resolucionFactura,
      sucursal,
      rangofecha,
      tipoDeTransmision,
    };
    let dataProvider = {
      diantipodocumentoid: Number(diantipodocumentoid),
      numerodocumento,
      documentodv: documentodv === "" ? "0" : documentodv,
      diantipopersonaid: Number(diantipopersonaid),
      residenteid: Number(procedenciaVendedor),
      razonsocial: ![3, 7].includes(Number(diantipodocumentoid)) ? `${nombre} ${apellido}` : razonsocial,
      responsabilidadtributariaid: Number(responsabilidadTributariaId),
      responsabilidadfiscal: responsabilidadFiscal.reduce((a, e) => a + e, ""),
      diantiporegimenid: Number(diantiporegimenid),
      correoelectronico,
      telefono,
      daneciudadid: daneciudadid !== "" ? Number(daneciudadid) : null,
      ciudadnoresidente: municipioPais.trim().toUpperCase(),
      dianpaisid: Number(pais.id),
      direccion,
      nombre,
      apellido,
      codigopostalid: codigopostalid !== "" ? Number(codigopostalid) : null,
    };

    if (formaPago === "1") {
      documentData = {
        ...documentData,
        paymethodid: null,
        descripcionPaymethodid: null,
      };
    }
    if (diantipopersonaid === null) {
      dataProvider = {
        ...dataProvider,
        diantipopersonaid: 2,
      };
    }
    const formValid = validator.allValid();

    if (!formValid) {
      validator.showMessages();
      forceUpdate(1);
      return null;
    }

    const errorMessage = [];
    if (!!resolucionFactura) {
      const fecha = new Date(fechaFactura).setHours(0, 0, 0, 0);

      const resolution = documentResolutionData.find((r) => r.id === Number(resolucionFactura));
      let { consecactual, consecfinal } = resolution;
      let fechafinal = resolution.fechafinal;
      let fechainicio = resolution.fechainicio;

      fechafinal = new Date(fechafinal).setHours(0, 0, 0, 0);
      fechainicio = new Date(fechainicio).setHours(0, 0, 0, 0);
      if (fecha > fechafinal) errorMessage.push(`La resolución seleccionada venció el ${formatDate(fechafinal, "dd/MM/yyyy")}`);

      if (fecha < fechainicio) errorMessage.push(`La resolución seleccionada es vigente a partir del  ${formatDate(fechainicio, "dd/MM/yyyy")}`);

      if (parseInt(consecactual) >= parseInt(consecfinal)) {
        errorMessage.push(`La resolución seleccionada ya no tiene rango de consecutivos disponibles.`);
      }
    } else {
      errorMessage.push(`Resolución no valida`);
    }
    setErrors(errorMessage);

    if (errorMessage.length === 0) {
      dispatch(dataProviderAction(dataProvider));
      dispatch(documentDataAction(documentData));
      handleChangeStep(1);
    }
  };

  /**
   * *Sincroniza nuevo valor Date Picker con state
   */
  const handleDate = (id, date, name) => {
    let range = rangofecha > 0 ? rangofecha : 1;
    let datepay = new Date(date);

    datepay.setDate(datepay.getDate() + range);
    handleUpdateForm({
      fechaFactura: new Date(date).toISOString(),
      fechapago: formaPago === "1" ? datepay.toISOString() : "",
    });
  };

  /**
   * Sincroniza nuevo valor tipo documento con state
   * @param {*} param0
   */
  const handleChangeTypeDocument = ({ target }) => {
    const typeDocument = target.value;
    handleUpdateForm({
      diantipodocumentoid: typeDocument,
      documentodv: "",
      numerodocumento: "",
      correoelectronico: "",
      departamento: "",
      direccion: "",
      fechaFactura: format(new Date(), "yyyy/MM/dd"),
      fechapago: format(getDatepay(), "yyyy/MM/dd"),
      formaPago: "1",
      daneciudadid: "",
      municipioPais: "",
      observaciones: "",
      paymethodid: "10",
      descripcionPaymethodid: "Efectivo",
      rangofecha: 0,
      razonsocial: "",
      diantiporegimenid: "",
      responsabilidadTributariaId: "",
      responsabilidadFiscal: [],
      resolucionFactura: "",
      tipoDeTransmision: "",
      sucursal: "",
      telefono: "",
      diantipopersonaid: "2",
      nombre: "",
      apellido: "",
      codigopostalid: "",
    });
    validator.purgeFields();

    dispatch(cleanSuplierAndProviderAction());
  };

  /**
   * Sincroniza componente multiple select con el form
   * @param {*} event
   */
  const syncMultipleChange = (event) => {
    const options = event.target;
    const value = [];
    for (let index = 0; index < options.value.length; index++) {
      value.push(options.value[index]);
    }

    handleUpdateForm({
      [options.name]: value,
    });
  };

  /**
   * * Obtiene los tipos de documento.
   * @returns {Array} Los tipos de documento filtrados según la procedencia del vendedor.
   */
  const getTypesDocument = () => {
    //si procedenciaVendedor es 1, se muestra solo el tipo de documento 3 (NIT)
    if (procedenciaVendedor === "1") {
      return typesDocument.filter((item) => item.value === "3");
    }
    return typesDocument;
  };

  return (
    <div
      style={{
        overflow: "auto",
        height: "72.9vh",
      }}
    >
      <div className={classes.titleData}>Datos del proveedor</div>
      <div className="spacingInvoice" />
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} lg={4}>
                <ToggleButtons
                  name={"procedenciaVendedor"}
                  label={"Procedencia del vendedor"}
                  value={procedenciaVendedor}
                  onChange={handleSellerOriginChange}
                  titleOne={"Residente"}
                  titleTwo={"No residente"}
                  txtTooltip={
                    <TooltipMessage
                      title={titleSellerOrigin}
                      message={messageSellerOrigin}
                      botton={bottonSellerOrigin}
                      href={urlSellerOrigin}
                      noButton={urlSellerOrigin === ""}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <SelectForm
                  label={"Tipo de documento *"}
                  name="diantipodocumentoid"
                  value={diantipodocumentoid}
                  options={typesDocumentFiltered}
                  onChange={handleChangeTypeDocument}
                  validator={validator}
                  validateOptions={"required"}
                />
              </Grid>
              {/* Valida si es tipo NIT */}
              {["3", "7", ""].includes(diantipodocumentoid) ? (
                <>
                  <Grid item xs={12} lg={4}>
                    <InputForm
                      name="numerodocumento"
                      label={"Número de documento *"}
                      disabled={diantipodocumentoid !== "" ? false : true}
                      value={numerodocumento}
                      onChange={(e) => {
                        if (diantipodocumentoid === "4") {
                          handleInputChange(e, "alphanumeric");
                        } else {
                          handleInputChange(e, "number");
                        }
                      }}
                      nameValidator={"numeroDocumento"}
                      onBlur={(e) => {
                        validateFieldsOnBlur(e, "numeroDocumento");
                        onBlurDocument(e);
                      }}
                      validator={validator}
                      validateOptions={["3"].includes(diantipodocumentoid) ? "required|min:3|max:15" : "required|min:3|max:20"}
                      maxLength={["3"].includes(diantipodocumentoid) ? 15 : 20}
                    />
                  </Grid>
                  {diantipodocumentoid !== "7" && (
                    <Grid item xs={12} lg={4}>
                      <InputForm name="documentodv" label={"Dígito verificación"} value={documentodv} disabled onChange={handleInputChange} />
                    </Grid>
                  )}
                  <Grid item xs={12} lg={4}>
                    <ToggleButtons
                      name={"diantipopersonaid"}
                      label={"Tipo de persona"}
                      value={diantipopersonaid}
                      onChange={handleChangeKindPerson}
                      titleOne={"Juridica"}
                      titleTwo={"Natural"}
                      txtTooltip={
                        <TooltipMessage
                          title={personTypeTitle}
                          message={personTypeMessage}
                          botton={personTypeButton}
                          href={personTypeHref}
                          noButton={personTypeHref === ""}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <InputForm
                      name="razonsocial"
                      label={"Razón Social *"}
                      value={razonsocial}
                      onChange={handleInputChange}
                      validator={diantipodocumentoid === "3" ? validator : null}
                      validateOptions={diantipodocumentoid === "3" ? "required|min:3|max:200" : "void"}
                      maxLength={200}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <SelectForm
                      label={"Régimen *"}
                      name="diantiporegimenid"
                      value={diantiporegimenid}
                      options={typesRegime}
                      onChange={handleInputChange}
                      validator={validator}
                      validateOptions={"required"}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} lg={4}>
                    <InputForm
                      name="numerodocumento"
                      label={"Número de documento *"}
                      disabled={diantipodocumentoid !== "" ? false : true}
                      value={numerodocumento}
                      onChange={(e) => {
                        if (diantipodocumentoid === "4") {
                          handleInputChange(e, "alphanumeric");
                        } else {
                          handleInputChange(e, "number");
                        }
                      }}
                      nameValidator={"numeroDocumento"}
                      onBlur={(e) => {
                        validateFieldsOnBlur(e, "numeroDocumento");
                        onBlurDocument(e);
                      }}
                      validator={validator}
                      validateOptions={["3"].includes(diantipodocumentoid) ? "required|min:3|max:15" : "required|min:3|max:20"}
                      maxLength={["3"].includes(diantipodocumentoid) ? 15 : 20}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <InputForm
                      name="nombre"
                      label={"Nombre(s) *"}
                      value={nombre}
                      validator={diantipodocumentoid === "3" ? null : validator}
                      validateOptions={diantipodocumentoid === "3" ? "void" : "required|min:3|max:200"}
                      onChange={handleInputChange}
                      maxLength={200}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <InputForm
                      name="apellido"
                      label={"Apellido(s) *"}
                      value={apellido}
                      onChange={handleInputChange}
                      validator={diantipodocumentoid === "3" ? null : validator}
                      validateOptions={diantipodocumentoid === "3" ? "void" : "required|min:3|max:200"}
                      maxLength={200}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <SelectForm
                      label={"Régimen *"}
                      name="diantiporegimenid"
                      value={diantiporegimenid}
                      options={typesRegime}
                      onChange={handleInputChange}
                      validator={validator}
                      validateOptions={"required"}
                    />
                  </Grid>
                </>
              )}
              {/* Termina Valida si es tipo NIT */}
              <Grid item xs={12} lg={4}>
                <SelectForm
                  label={"Responsabilidad Tributaria *"}
                  name="responsabilidadTributariaId"
                  value={responsabilidadTributariaId}
                  options={taxFilter}
                  onChange={handleInputChange}
                  validator={validator}
                  validateOptions={"required"}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <MultiSelectForm
                  label={"Responsabilidad Fiscal *"}
                  name="responsabilidadFiscal"
                  value={responsabilidadFiscal}
                  options={fiscalResponsibility}
                  onChange={syncMultipleChange}
                  renderValue={(selected) =>
                    responsabilidadFiscal
                      .map((value) => {
                        let name = fiscalResponsibility.filter((category) => category.id.toString() === value);
                        return name[0]?.descripcion;
                      })
                      .join(", ")
                  }
                  placeholder={"Seleccione..."}
                  validator={validator}
                  validateOptions={"required"}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputForm
                  name="correoelectronico"
                  label={"Correo Electrónico *"}
                  value={correoelectronico}
                  onChange={(e) => handleInputChange(e, "email")}
                  validator={validator}
                  validateOptions={"required|min:10|max:200|email"}
                  maxLength={200}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputForm
                  name="telefono"
                  label={"Teléfono *"}
                  value={telefono}
                  onChange={(e) => handleInputChange(e, "phone")}
                  validator={validator}
                  validateOptions={"required|min:7|max:15"}
                  maxLength={15}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                {["2"].includes(procedenciaVendedor) ? (
                  <CustomAutoComplete
                    name="pais"
                    label={"País *"}
                    value={pais}
                    options={sortByProperty(countriesList, "descripcion").filter((item) => item.descripcion !== "Colombia")}
                    disabled={countriesList.length === 0 ? true : false}
                    textNoOption="No se encontraron resultados."
                    onChange={handleAutocompleteChange}
                    validator={validator}
                    validateOptions={"required"}
                    fullWidth={false}
                  />
                ) : (
                  <SelectForm
                    label={"Departamento *"}
                    name="departamento"
                    value={departamento}
                    options={statesList}
                    onChange={handleInputChange}
                    validator={validator}
                    validateOptions={"required"}
                  />
                )}
              </Grid>
              <Grid item xs={12} lg={4}>
                {["2"].includes(procedenciaVendedor) ? (
                  <InputForm
                    name="municipioPais"
                    label={"Ciudad *"}
                    disabled={diantipodocumentoid !== "" ? false : true}
                    value={municipioPais}
                    onChange={(e) => handleInputChange(e, "lettersSpace")}
                    validator={validator}
                    validateOptions={"required|min:1|max:100"}
                  />
                ) : (
                  <SelectForm
                    label={"Municipio *"}
                    name="daneciudadid"
                    value={daneciudadid}
                    options={getCitiesStateResponse}
                    onChange={handleInputChange}
                    validator={validator}
                    validateOptions={"required"}
                  />
                )}
              </Grid>
              {procedenciaVendedor !== "2" && (
                <Grid item xs={12} lg={4}>
                  <Grid container>
                    <Grid item xs={11} lg={11}>
                      <SelectForm
                        label={"Código Postal *"}
                        name="codigopostalid"
                        value={codigopostalid}
                        options={getPostalCodesState}
                        onChange={handleInputChange}
                        validator={validator}
                        validateOptions={"required"}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      lg={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CustomTooltip
                        message={
                          <TooltipMessage title={titlePostalCode} message={messagePostalCode} botton={bottonPostalCode} href={urlPostalCode} />
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} lg={diantipodocumentoid === "7" ? 12 : 4}>
                <InputForm
                  name="direccion"
                  label={"Dirección *"}
                  value={direccion}
                  onChange={handleInputChange}
                  validator={validator}
                  validateOptions={"required|min:1|max:100"}
                  maxLength={100}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="spacingInvoice" />
        {/* Sección Datos del Documento */}
        <div className={classes.titleData}>Datos del Documento</div>
        <div className="spacingInvoice" />
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} lg={4}>
                <SelectForm
                  label={"Forma  de generación y transmisión *"}
                  name="tipoDeTransmision"
                  value={tipoDeTransmision}
                  options={requestTransmissionType ?? []}
                  onChange={handleInputChange}
                  validator={validator}
                  validateOptions={"required"}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <DateForm
                  name="fechaFactura"
                  value={fechaFactura}
                  onChange={handleDate}
                  label={"Fecha de Documento"}
                  minDate={getDateNotBeforeDS(7)}
                  maxDate={getDateNotAfterDS(7)}
                  validator={validator}
                  validateOptions={"required"}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ToggleButtons
                  name={"formaPago"}
                  label={"Forma de pago"}
                  value={formaPago}
                  onChange={handleChangePaymentMethod}
                  titleOne={"Crédito "}
                  titleTwo={"Contado"}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                {formaPago === "1" ? (
                  <DateForm
                    name={"fechapago"}
                    value={fechapago}
                    onChange={handleDatePay}
                    label="Fecha Vigencia"
                    minDate={getDateMinPay(fechaFactura)}
                    validator={formaPago === "1" ? validator : null}
                    validateOptions={
                      formaPago === "1"
                        ? [
                            "required",
                            {
                              dateNotLessThanOrEqual: fechaFactura,
                            },
                          ]
                        : "void"
                    }
                  />
                ) : (
                  <ExpandableSelect
                    name={"paymethodid"}
                    label={"Medio Pago"}
                    value={paymethodid}
                    options={defaultPaymentMethods}
                    onChange={handleInputChange}
                    optionsSeeMore={othersPaymentMethods}
                    validator={formaPago !== "1" ? validator : null}
                    validateOptions={formaPago === "1" ? "void" : "required"}
                  />
                )}
              </Grid>
              <Grid item xs={12} lg={4}>
                <SelectForm
                  label={"Sucursal *"}
                  name="sucursal"
                  value={sucursal}
                  options={documentBranchData}
                  onChange={handleInputChange}
                  validator={validator}
                  validateOptions={"required"}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <SelectForm
                  label={"Resolución Factura *"}
                  name="resolucionFactura"
                  value={documentResolutionData.length > 0 ? resolucionFactura : ""}
                  options={documentResolutionData}
                  onChange={handleInputChange}
                  validator={validator}
                  validateOptions={"required"}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputForm
              name="observaciones"
              maxLength={500}
              label={"Observaciones"}
              multiline={true}
              value={observaciones}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <div className="spacingInvoice" />
        <Grid container>
          <Grid item lg={8} xs={12}>
            <div className={classes.containerError}>
              {errors.map((err, index) => (
                <div key={index}>
                  <span>{`${err}.`}</span>
                  <br />
                </div>
              ))}
            </div>
          </Grid>

          <Grid item lg={4} xs={12}>
            <button className="buttonInvoiceConfirm" type="submit">
              Continuar
            </button>
          </Grid>
        </Grid>
      </form>
      <div className="spacingInvoice" />
    </div>
  );
};

export default DocumentInformation;
